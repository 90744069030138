<template>
  <div>
    <div class="reserved-area-top-box-wrapper">
      <div class="container">
        <div class="reserved-area-top-box d-flex justify-content-center align-items-center flex-column">
          <p class="reserved-area-top-secondary-title">Notificações push desactivadas</p>
          <p class="reserved-area-top-secondary-subtitle">Por favor, permita que a BOXPT envie notificações push pelo seu browser</p>
          <a href="" class="reserved-area-top-secondary-link">Permitir</a>
        </div>
      </div>
    </div>
    <div class="reserved-area-page">
      <div class="container">
        <div class="d-xl-flex">
          <div class="reserved-area-page-column">
            <reservedAreaMenu/>
          </div>
          <div class="reserved-area-page-column">
            <form>
              <div class="reserved-area-form-group-wrapper">
                <p class="section-title reserved-area-form-title">NOTIFICAÇÕES DA PLATAFORMA</p>
                <div class="reserved-area-form-group">
                  <div class="row">
                    <div class="reserved-area-form-column col-12 col-lg-6 col-xl-12 col-xxl-6">
                      <div class="form-field">
                        <FormulateInput type="select" name="new-daily-workout-alert" label="Alerta de Novo Treino do Dia" :options="{first: 'Nenhum', second:'Push', third: 'E-mail'}" />
                      </div>
                    </div>
                    <div class="reserved-area-form-column col-12 col-lg-6 col-xl-12 col-xxl-6">
                      <div class="form-field">
                        <FormulateInput type="select" name="workout-alert" label="Alerta de Treino" :options="{first: 'Nenhum', second:'Push', third: 'E-mail'}" />
                      </div>
                    </div>
                    <div class="reserved-area-form-column col-12 col-lg-6 col-xl-12 col-xxl-6">
                      <div class="form-field">
                        <FormulateInput type="select" name="workout-alert" label="Alerta Validade do Plano" :options="{first: 'Nenhum', second:'Push', third: 'E-mail'}" />
                      </div>
                    </div>
                    <!--<div class="reserved-area-form-column col-12 col-lg-6 col-xl-12 col-xxl-6">
                      <div class="form-field">
                        <FormulateInput type="select" name="pt-alert" label="Alerta Treinador PT" :options="{first: 'Nenhum', second:'Push', third: 'E-mail'}" />
                      </div>
                    </div>-->
                  </div>
                </div>
              </div>
              <div class="reserved-area-form-btn-wrapper d-flex justify-content-center align-items-center">
                <button type="submit" class="reserved-area-form-btn generic-btn red-gradient ">Guardar</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import reservedAreaMenu from "@/components/reserved-area/reserved-area-menu.vue";

export default {
  name: "notifications",
  components: {
    reservedAreaMenu,
  },
  metaInfo () {
    return {
      title: this.$root.translations.definicoes,
    }
  },
  created() {
    this.$root.pageTitle = this.$root.translations.definicoes;
  }
}
</script>